import parse, { domToReact } from "html-react-parser";
import { Link } from "@remix-run/react";
import { cn } from "~/lib/utils";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { parseYoutubeId } from "~/lib/parseYoutubeId";

function CkEditorContent({
  htmlContent,
  className,
}: {
  htmlContent: string;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "ck-content prose prose-quoteless lg:prose-xl md:max-w-full",
        className,
      )}
    >
      {parse(htmlContent, {
        replace: (node) => {
          // Check if the node is an <a> tag with an internal link
          if (node.type === "tag" && node.attribs) {
            if (node.name === "a") {
              return (
                <Link to={node.attribs.href}>
                  {domToReact(node.children as any[])}
                </Link>
              );
            }

            if (node.name === "img") {
              if (!node.attribs.src) return undefined;
              return (
                <img
                  src={node.attribs.src}
                  alt={node.attribs.alt}
                  srcSet={node.attribs.srcset}
                />
              );
            }

            if (node.name === "figcaption") {
              return (
                <figcaption className={"border-b border-border"}>
                  {domToReact(node.children as any[])}
                </figcaption>
              );
            }

            if (node.name === "oembed") {
              const youtubeVideoId = parseYoutubeId(node.attribs.url);
              if (!youtubeVideoId) return null;
              return (
                <LiteYouTubeEmbed
                  id={youtubeVideoId}
                  title={node.attribs.title ?? ""}
                />
              );
            }

            return undefined; // Let other tags remain unchanged
          }
        },
      })}
    </div>
  );
}

export default CkEditorContent;
